import './TypeAheadInput.scss';
import * as common from '../../utils/common';
import axios from 'axios';
import React, { Component } from 'react';
import TypeAheadResults from '../TypeAheadResults/TypeAheadResults';
import { DebounceInput } from 'react-debounce-input';


export default class TypeAheadInput extends Component{
  //Lifecycle
  constructor(props){
    super(props);

    this.state = {
      searchFocused: false,
      searchStr: '',
      searchResults: [],
      searchSelected: null, //clicked on in list
      searchKeySelected: null //key up/down selection
    };
  }


  //Events
  input_onBlur = () => {
    if(this.state.searchResults.length < 1)
      this.setState({
        searchFocused: false,
        searchInProgress: false
      });
    this.props.input_onBlur();
  };
  input_onChange = e => {
    this.setState({
      searchStr: e.target.value,
      searchInProgress: true
    });
    this.props.onChange(e.target.value);
    common.typeahead_query(e.target.value).then(results => this.setState({
        searchInProgress: false,
        searchResults: this.state.searchFocused ? results : []
      }))
      .catch(function(error){
        console.error(error);
      });
  };
  input_onFocus = () => {
    this.setState({
      searchFocused: true
    });
  };
  input_onKeyDown = e => {
    e = e || window.event;
    let selectedIndex = this.state.searchResults.findIndex(r => r.key == this.state.searchKeySelected);

    //select using enter
    if(e.keyCode == '13'){
      this.results_onClick(this.state.searchResults[selectedIndex]);
      e.preventDefault();
    }

    //if they tab
    else if(e.keyCode == '9'){
      if(this.state.searchResults.length > 0){
        e.preventDefault();
        if(selectedIndex >= 0) this.select(this.state.searchResults[selectedIndex]);
        else this.select(this.state.searchResults[0]);
      }
    }

    //see if they used arrows when the dropdown was showing
    else if(this.state.searchResults.length > 0){
      let newIndex = this.typeahead_keySelectIndex(e, selectedIndex, this.state.searchResults);
      if(typeof newIndex != 'undefined' && newIndex != null){
        if(this.state.searchResults[newIndex])
          this.setState({searchKeySelected: newIndex == null ? newIndex : this.state.searchResults[newIndex].key});
      }
      else{
        //If they change the text without selecting from the dropdown we need to clear selected to make it query axios on submit
        this.setState({
          searchSelected: null,
          searchKeySelected: null
        });
        this.props.onSelect(null);
      }
    }

    //If they change the text and the dropdown is not open
    else{
      this.setState({
        searchSelected: null,
        searchKeySelected: null
      });
      this.props.onSelect(null);
    }
  };
  results_onClick = item => {
    this.select(item);
  };
  results_onClickOutside = () => {
    this.setState({
      searchFocused: false,
      searchResults: [],
      searchSelected: null,
      searchKeySelected: null
    });
    this.props.onSelect(null);
  };


  //Functions
  clear = () => {
    this.setState({
      searchFocused: false,
      searchResults: [],
      searchSelected: null,
      searchKeySelected: null
    });
    this.props.onSelect(null);
    this.props.onChange('');
  };
  select = item => {
    if(item){
      this.setState({
        searchFocused: false,
        searchResults: [],
        searchSelected: item,
        searchStr: item.label,
        searchKeySelected: null
      });
      this.props.onSelect(item);
      this.props.onChange(item.label);
    }
    else{
      this.setState({
        searchFocused: false,
        searchResults: [],
        searchSelected: null,
        searchKeySelected: null
      });
      this.props.onSelect(null);
      this.props.onChange(null);
    }
  };
  typeahead_keySelectIndex = (e, selectedIndex, results) => {
    if(e.keyCode == '38'){// up arrow
      e.preventDefault();
      if(selectedIndex != null){
        if(selectedIndex > 0) return selectedIndex - 1;
        else return null;
      }
    }
    else if(e.keyCode == '40'){// down arrow or tab
      e.preventDefault();
      if(selectedIndex != null){
        if(selectedIndex < results.length - 1) return selectedIndex + 1;
      }
      else return 0;
    }
  };


  //Rendering
  render(){
    const inProgressClass = this.state.searchInProgress ? 'typeahead-input--in-progress' : '';

    return (
      <div className={`typeahead-input ${inProgressClass}`}>
        <DebounceInput autoComplete="off"
                       className={`typeahead-input ${this.props.input_className}`}
                       id={this.props.id}
                       onBlur={this.input_onBlur}
                       onChange={this.input_onChange}
                       onFocus={this.input_onFocus}
                       onKeyDown={this.input_onKeyDown}
                       debounceTimeout={300}
                       type="text"
                       value={this.state.searchStr}/>
        <TypeAheadResults results={this.state.searchResults}
                          keySelected={this.state.searchKeySelected}
                          onClick={this.results_onClick}
                          onClickOutside={this.results_onClickOutside}/>
        <div className="typeahead-input__spinner">
          <div className="typeahead-input__spinner-image tg-spinner"></div>
        </div>
      </div>
    );
  };
}
