import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import App from './App';
// import rootReducer from './redux/rootReducer';
// import rootSaga from './redux/rootSaga';
import { BrowserRouter } from 'react-router-dom';
// import concept from './redux/concept';


// const sagaMiddleware = createSagaMiddleware();
// const store = createStore(
//   rootReducer,
//   compose(
//     applyMiddleware(sagaMiddleware),
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
//   ),
// );
// sagaMiddleware.run(rootSaga);
// store.dispatch(concept.actionCreators.loadStart());

{/*<Provider store={store}>*/}
render(
  <BrowserRouter>
    <App/>
  </BrowserRouter>,
  document.getElementById('root'),
);
