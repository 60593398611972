import React from 'react';
import './Footer.scss';


export default function Footer(props){
  // console.log(`Footer`, props);
  const render = () => {
    return (
      <div className="footer">
        <div className="footer__content">
          <ul className="footer__links">
            <li className="footer__links-item">
              <a className="footer__links-link" href='https://www.metro.net/projects/nextgen/'>About the NextGen Plan</a>
            </li>
            <li className="footer__links-item">
              <a className="footer__links-link" href="https://www.metro.net/about/site-information/privacy-policy/">Privacy Statement</a>
            </li>
          </ul>
        </div>
      </div>
    );
  };
  return render();
}
