import React from 'react';
import './Header.scss';
import LogoMetro from '../../assets/logo-metro.png';


export default function Header(props){
  // console.log(`Header`, props);
  const render = () => {
    return (
      <div className="header">
        <div className="header__content">
          <a className="header__logo"
             href='/'>
            <img alt="Metro Logo"
                 className="header__logo-image"
                 src={LogoMetro}/>
          </a>
          <div className="header__name">
            <div className="header__name-text">
              NEXTGEN
              <br/>Trip Evaluator
            </div>
          </div>
        </div>
      </div>
    );
  };
  return render();
}
