import React from 'react';
import './Survey.scss';


export default function Survey(props){
  // console.log(`Survey`, props);
  const render = () => {
    return (
      <div className="survey">
        <iframe className="survey-iframe"
                height="448"
                title="Embedded Wufoo Form"
                allowtransparency="true"
                frameBorder="0"
                scrolling="no"
                style={{width: '100%', border: 'none'}}
                src={`https://metrola.wufoo.com/embed/${process.env.REACT_APP_WUFOO_FORM_ID}/def/Field722=${props.nextGenRating}&Field726=${props.apiRequestId}&Field730=${props.apiUserId}`}/>
      </div>
    );
  };
  return render();
}
