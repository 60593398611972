import './Directions.scss';
import * as common from '../../utils/common';
import iconChevronDown from '../../assets/icon-chevron-down.svg';
import iconChevronUp from '../../assets/icon-chevron-up.svg';
import iconPinA from '../../assets/icon-pin-a.svg';
import iconPinB from '../../assets/icon-pin-b.svg';
import iconStopDot from '../../assets/icon-stop-dot.svg';
import iconStopDotInverse from '../../assets/icon-stop-dot-inverse.svg';
import iconWalk from '../../assets/icon-walk.svg';
import moment from 'moment';
import React, { Component } from 'react';


export default class Directions extends Component{
  lastSwipePosition;


  //Lifecycle
  constructor(props){
    super(props);
    this.state = {
      legStepsOpen: {}
    };
  }


  //Events
  stepsToggle_onClick = key => this.setState({legStepsOpen: {...this.state.legStepsOpen, [key]: !this.state.legStepsOpen[key]}});


  //Rendering
  render(){
    if(!this.props.currentTrip) return null;

    const tripDuration = Math.round(this.props.currentTrip.total_duration / 60);
    const tripStartStr = moment(this.props.currentTrip.startTime).format('h:mmA');
    const tripEndStr = moment(this.props.currentTrip.endTime).format('h:mmA');
    const distanceUnit = .77 / 1235.821; //todo: maybe it can be more accurate; not sure what this is. 1 == 1/2 kilometer ?
    const totalWalk = parseFloat(this.props.currentTrip.walkDistance * distanceUnit).toFixed(2);

    return (
      <div className="directions">
        <div className="directions-duration">Duration {tripDuration} minutes ({tripStartStr} - {tripEndStr})</div>

        <div className="directions__legs">
          <div className="directions__legs-start-icon">
            <img src={iconPinA} alt="Start marker"/>
          </div>
          {this.props.currentTrip.legs.map((leg, leg_i) => {
            let timeStr = moment(leg.startTime).format('h:mmA');
            const legNumberClass = `directions__legs-leg--${leg_i == 0 ? 'first' : leg_i == this.props.currentTrip.legs.length - 1 ? 'last' : ''}`;
            const modeClass = `directions__legs-mode--${leg.mode.toLowerCase()}`;
            const lineClass = `directions__legs-line--${leg.mode.toLowerCase()}`;
            const distanceStr = parseFloat(leg.distance * distanceUnit).toFixed(2);
            const steps = leg.mode == 'WALK' ?
                          leg.steps.map((step, step_i) => ({
                            key: '' + leg.distance + step.lat + step.lon,
                            text: `${step.relativeDirection == 'DEPART' ? 'Start on' : step.relativeDirection.toLowerCase()} on ${step_i == 0 ? `${step.streetName} heading ${step.absoluteDirection.toLowerCase()}` : `to ${step.streetName}`}`
                          })) :
                          leg.intermediateStops.map(stop => ({
                            key: '' + stop.departure + stop.lat + stop.lon,
                            text: stop.name
                          }));
            if(this.props.currentTrip.legs.length > leg_i + 1 && leg.mode != 'WALK')
              steps.push({
                key: '' + leg.startTime,
                text: this.props.currentTrip.legs[leg_i + 1].from.name
              });
            const stepListClass = this.state.legStepsOpen[leg.startTime] ? 'directions__legs-detail-steps-list--is-open' : '';

            let routeName = '';
            if(leg.routeShortName && leg.routeShortName.match(/\d+/g))
              routeName = leg.routeShortName.match(/\d+/g).map(Number).join(' ');
            if(routeName.length < 1 && leg.routeLongName && leg.routeLongName.match(/\d+/g))
              routeName = leg.routeLongName.match(/\d+/g).map(Number).join(' ');//gets numbers from name
            if(routeName.length < 1 && leg.routeLongName && leg.routeLongName.match(/\(([^)]+)\)/g)){
              routeName = leg.routeLongName.match(/\(([^)]+)\)/g);//gets string name in parentheses like (Blue) from name
              routeName = routeName && routeName[0] ? routeName[0].replace('(', '').replace(')', '') : '';
            }

            let routeColor = leg.routeColor ? `#${leg.routeColor}` : common.modeStyles[leg.mode].paint['line-color'];
            let lineStyle = {borderColor: routeColor};

            return (
              <div className={`directions__legs-leg ${legNumberClass}`}
                   key={leg.startTime}>
                <div className={`directions__legs-mode ${modeClass}`}>
                  <div className="directions__legs-time">{timeStr}</div>
                  <div className="directions__legs-mode-stop">
                    <div className="directions__legs-mode-stop-icon-container">
                      <div className="directions__legs-mode-stop-icon">
                        {leg.mode == 'BUS' && (<svg alt="Bus" className="directions__legs-mode-stop-icon-image" height="25" width="35" viewBox="0 0 35.3 37" xmlns="http://www.w3.org/2000/svg">
                            <g fill={routeColor}>
                              <path d="M3.4 26v.8l1 .3c.3.1 7 1.5 13.2 1.5 7 0 13-1.4 13.2-1.5l1-.3v-9.3c3.4-.5 3.4-4.2 3.4-6.4 0-.4-.2-.8-.5-1.1-.5-.4-1.2-.7-2.4-.8h-.5V5.1c0-2-1.9-3.4-5.7-4.3-3.4-.7-7.3-.8-8.5-.8-1.1 0-5.1.1-8.6.8-3.7.9-5.6 2.3-5.6 4.3v4.1h-.5c-1.2.1-1.9.4-2.4.8-.3.3-.5.7-.5 1.1 0 2.2 0 5.9 3.4 6.4V26zm4.2-.8c-.9 0-1.7-.7-1.7-1.7 0-.9.8-1.7 1.7-1.7s1.7.8 1.7 1.7c-.1.9-.8 1.7-1.7 1.7m20.1 0c-.9 0-1.7-.8-1.7-1.7s.7-1.7 1.7-1.7c.9 0 1.7.8 1.7 1.7s-.8 1.7-1.7 1.7M12.6 2.5h10.1c.5 0 .8.4.8.8 0 .5-.4.8-.8.8H12.6c-.5 0-.8-.4-.8-.8s.3-.8.8-.8M5 6.7h25.2v9.9c0 1.1-1.4 2.7-2.4 2.7H7.6c-1.1 0-2.5-1.5-2.5-2.6v-10z"></path>
                              <path d="M31.3 28.7c-.3.1-6.3 1.5-13.7 1.5-6.5 0-13.3-1.5-13.6-1.5l-.6-.2v3.4c0 .5.4.8.8.8H5v2.5c0 1 .7 1.8 1.6 1.8h1.9c.9 0 1.6-.8 1.6-1.7v-2.5h15.1v2.5c0 .9.7 1.7 1.6 1.7h1.9c.9 0 1.6-.8 1.6-1.7v-2.5h.8c.5 0 .8-.4.8-.8v-3.4z"></path>
                            </g>
                          </svg>
                        )}
                        {(leg.mode == 'TRAM' || leg.mode == 'RAIL' || leg.mode == 'SUBWAY') && (<svg alt={leg.mode} className="directions__legs-mode-stop-icon-image" width="19px" height="28px" viewBox="0 0 19 28" version="1.1" xmlns="http://www.w3.org/2000/svg">
                          <g id="Mobile-11/1-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="4.0-Bus-Open" transform="translate(-29.000000, -316.000000)">
                              <g id="Group-4" transform="translate(7.000000, 302.000000)">
                                <g id="icon-blue-line" transform="translate(14.000000, 12.000000)">
                                  <path d="M34.999,17.5 C34.999,27.166 27.167,35 17.499,35 C7.834,35 -1.03028697e-13,27.166 -1.03028697e-13,17.5 C-1.03028697e-13,7.834 7.834,0 17.499,0 C27.167,0 34.999,7.834 34.999,17.5" id="Fill-14"></path>
                                  <path d="M17.224,3.5058 C17.224,4.0408 16.79,4.4778 16.252,4.4778 C15.721,4.4778 15.281,4.0448 15.281,3.5098 C15.281,2.9748 15.715,2.5398 16.252,2.5378 C16.786,2.5378 17.224,2.9708 17.224,3.5058" id="Fill-16" fill={routeColor}></path>
                                  <path d="M17.7766931,3.5094 C17.7766931,4.0444 18.2127,4.4794 18.7467,4.47740687 C19.2797,4.47740687 19.7187,4.0404 19.7147,3.5054 C19.7147,2.9704 19.2797,2.5374 18.7427,2.5374 C18.2087,2.5394 17.7747,2.9744 17.7766931,3.5094" id="Fill-18" fill="#152935"></path>
                                  <path d="M25.222,23.2534 C25.086,23.2884 21.792,24.1494 17.524,24.1494 C13.731,24.1494 9.953,23.2964 9.797,23.2574 L9.745,23.2454 L9.745,23.3504 C9.745,23.6504 9.894,24.0064 10.106,24.2174 L11.374,25.4584 C11.582,25.6644 11.938,25.8124 12.235,25.8124 L22.762,25.8124 C23.046,25.8124 23.394,25.6834 23.608,25.5054 L24.847,24.4744 C25.09,24.2704 25.257,23.9104 25.257,23.5954 L25.257,23.2414 L25.222,23.2534 Z" id="Fill-20" fill={routeColor}></path>
                                  <path d="M24.7016,29.4627 C24.7866,29.5877 24.9856,29.6897 25.1386,29.6897 L25.9476,29.6897 C26.0996,29.6897 26.1516,29.5877 26.0606,29.4627 L24.0096,26.5887 C23.9236,26.4667 23.7216,26.3637 23.5696,26.3637 L22.7646,26.3637 C22.6116,26.3637 22.5566,26.4667 22.6486,26.5867 L23.1226,27.2477 C23.2116,27.3707 23.1616,27.4737 23.0116,27.4737 L11.9886,27.4737 C11.8396,27.4737 11.7846,27.3707 11.8746,27.2477 L12.3486,26.5867 C12.4386,26.4667 12.3896,26.3637 12.2356,26.3637 L11.4276,26.3637 C11.2756,26.3637 11.0786,26.4667 10.9916,26.5887 L8.9366,29.4627 C8.8506,29.5877 8.9036,29.6897 9.0546,29.6897 L9.8616,29.6897 C10.0116,29.6897 10.2116,29.5877 10.2996,29.4627 L10.7796,28.7937 C10.8666,28.6697 11.0616,28.5687 11.2136,28.5687 L23.7796,28.5687 C23.9336,28.5687 24.1336,28.6697 24.2236,28.7937 L24.7016,29.4627 Z" id="Fill-22" fill={routeColor}></path>
                                  <path
                                    d="M20.8224,7.5254 C20.8224,7.2204 20.5754,6.9714 20.2744,6.9714 L14.7304,6.9714 C14.4254,6.9714 14.1744,7.2204 14.1744,7.5254 C14.1744,7.8304 14.4254,8.0774 14.7304,8.0774 L20.2744,8.0774 C20.5754,8.0774 20.8224,7.8304 20.8224,7.5254 L20.8224,7.5254 Z M23.5734,15.0464 L23.5734,10.7414 C23.5734,10.7084 23.5734,10.6734 23.5754,10.6404 C23.5834,10.4714 23.5974,10.1854 23.3794,9.9594 C23.2864,9.8604 23.1144,9.7424 22.8344,9.7424 L12.1854,9.7424 C11.9074,9.7424 11.7304,9.8584 11.6314,9.9574 C11.3994,10.1894 11.3994,10.4814 11.4034,10.6984 L11.4034,15.0934 C11.4034,15.9724 12.3334,16.9034 13.2084,16.9034 L21.8124,16.9034 C22.6654,16.9034 23.5734,15.9504 23.5734,15.0464 L23.5734,15.0464 Z M21.3784,19.7154 C21.3784,20.3294 21.8744,20.8254 22.4884,20.823406 C23.0974,20.823406 23.5974,20.3254 23.5974,19.7134 C23.5934,19.1004 23.0934,18.6044 22.4824,18.606394 C21.8704,18.6084 21.3784,19.1024 21.3784,19.7154 L21.3784,19.7154 Z M12.5124,18.606394 C11.9034,18.6084 11.4034,19.1024 11.4034,19.7154 C11.4034,20.3294 11.9034,20.8234 12.5154,20.8234 C13.1264,20.8234 13.6224,20.3254 13.620406,19.7134 C13.6184,19.1004 13.1244,18.6044 12.5124,18.606394 L12.5124,18.606394 Z M9.7434,22.1064 L9.7434,18.4454 L9.7434,8.6224 C9.7434,7.0254 11.3354,6.2404 12.6714,5.8634 C14.3784,5.3804 16.4514,5.3084 17.5364,5.3084 C18.6134,5.3084 20.6764,5.3804 22.3664,5.8634 C23.6864,6.2404 25.2554,7.0254 25.2554,8.6224 L25.2554,18.6084 L25.2554,22.0944 L24.9344,22.1824 C24.9074,22.1904 21.6524,23.0394 17.5244,23.0394 C13.8594,23.0394 10.0834,22.1864 10.0404,22.1764 L9.7434,22.1064 Z"
                                    id="Fill-24" fill={routeColor}></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>)}
                        {leg.mode == 'WALK' && (<img alt="Walk"
                                                     className="directions__legs-mode-stop-icon-image"
                                                     src={iconWalk}/>)}
                      </div>
                    </div>
                    <div className="directions__legs-mode-stop-text">{routeName}</div>
                  </div>
                </div>
                <div className={`directions__legs-line ${lineClass}`} style={lineStyle}>
                  <img className="directions__legs-line-dot" src={iconStopDot} alt="Stop Dot"/>
                </div>
                <div className="directions__legs-detail">
                  <div className="directions__legs-detail-title">{leg_i == 0 ? 'Origin' : leg.from.name}</div>
                  <div className="directions__legs-detail-sub">
                    {leg.mode == 'WALK' && (<span>Walk <strong>{distanceStr} mi</strong></span>)}
                    {leg.mode != 'WALK' && (<span>{leg.routeLongName} {leg.routeShortName} to {leg.to.name}</span>)}
                  </div>
                  <div className="directions__legs-detail-steps-toggle"
                       onClick={() => this.stepsToggle_onClick(leg.startTime)}>
                    {leg.mode == 'WALK' ? 'detailed steps' : `${steps.length} stops`}
                    <img alt="arrow"
                         src={this.state.legStepsOpen[leg.startTime] ? iconChevronUp : iconChevronDown}/>
                  </div>
                  <ol className="directions__legs-detail-steps">
                    <li className={`directions__legs-detail-steps-list ${stepListClass}`}>
                      {steps.map(step => (
                        <div className="directions__legs-detail-steps-list-item"
                             key={step.key}>
                          {leg.mode != 'WALK' && (
                            <img alt="Intermediate stop dot"
                                 className="directions__legs-detail-steps-list-item-dot"
                                 src={iconStopDotInverse}/>
                          )}
                          {step.text}
                        </div>
                      ))}
                    </li>
                  </ol>
                </div>
              </div>
            );
          })}
          <div className="directions__legs-end-icon">
            <img src={iconPinB} alt="End marker"/>
          </div>
          <div className={`directions__legs-leg directions__legs-leg--last`}>
            <div className={`directions__legs-mode`}>
              <div className="directions__legs-time">{tripEndStr}</div>
            </div>
            <div className="directions__legs-detail">
              <div className="directions__legs-detail-title">
                Destination
                <br/>Total Walk {totalWalk} {totalWalk == 1 ? 'mile' : 'miles'}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
